import i18next from "i18next";
export const AuthWhiteList = [
  "/login",
  "/register",
  "/forgot-password",
  "/oauth/authorize",
];

export enum RES_CODE {
  success = 0,
  no_auth = 401,
}

export interface IListParams {
  keyword?: string;
  page: number;
  pageSize: number;
}

export interface IlistRes<T = any> {
  list: T[];
}

export const AuthStorageKey = "auth-storage";

export const SettingStorageKey = "setting-storage-v1.0";

export enum ASSET_TYPE {
  cash = 1,
  strategy = 2,
}
export enum HOARD_TYPE {
  hold,
  exchange,
}
export enum COIN_TYPE {
  USDT = "USDT",
  BTC = "BTC",
}

export enum STRATEGY_TYPE_KEYS {
  Grid = "Grid",
  Power = "Power",
}

export const STRATEGY_TYPE: Record<STRATEGY_TYPE_KEYS, string> = {
  Grid: "网格",
  Power: "抄底逃顶",
};

export enum STRATEGY_STATUS_KEYS {
  runing = "1",
  close = "2",
  applying = "3",
  redeming = "4",
}

export enum STRATEGY_STATUS {
  "",
  "运行中",
  "关闭",
  "申购中",
  "赎回中",
}

export const langItems = [
  {
    key: "en",
    label: "English",
    title: "ENG",
  },
  {
    key: "zh",
    label: "中文-简体",
    title: "中文-简体",
  },
  // {
  //   key: "zh-Hant",
  //   label: "中文-繁體",
  //   title: "中文-繁體",
  // },
] as const;

export const NET_OPTIONS: Record<
  COIN_TYPE,
  Record<"value" | "label", string>[]
> = {
  USDT: [
    { value: "ETH", label: "ETH" },
    { value: "TRX", label: "TRX" },
  ],
  BTC: [{ value: "BTC", label: "BTC" }],
};

export interface IWhitelistItem {
  ID: number;
  address: string;
  coin: string;
  network: string;
  tag: string;
}

export enum AccountGrade {
  default,
  exprience,
  year,
  lifetime,
}
export enum AccountGradeText {
  default = "non_member",
  exprience = "experience_member",
  year = "year_member",
  lifetime = "lifetime_member",
}

export const AccountGradeTextArr = [
  "non_member",
  "experience_member",
  "year_member",
  "lifetime_member",
];

export enum MESSAGE_STATUS {
  new = "1",
  readed = "2",
}
