import React, { useEffect, useMemo, useState } from 'react';
import { FaHandHoldingUsd } from "react-icons/fa";
import { useRouter } from 'next/router';
import LangSelect from './LangSelect';
import SwitchTheme from './SwitchTheme';
import { getMessageList } from '@/api/user';
import { Layout, Flex, Avatar, Popover } from 'antd';
import { SidebarConfig } from '@/types/sidebar';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { useAuthStore } from '@/store/useAuthStore';
import { useMount, useRequest } from "ahooks";
import { ApiResponse } from "@/api/axiosInstance";
import { Theme, Question, More, Bell, Rightsmall, Check } from '~/svgs/header';
import Back from './Back';
import { langItems, MESSAGE_STATUS } from '@/helper/const';
import { IMessage, useSettingStore } from '@/store/useSetting';
import EmptyCont from '../EmptyCont';
import dayjs from 'dayjs';
import EllipsisMiddle from '../EllipsisMiddle';
import { getChangePrice, IPrice } from '@/api/account';
import { RedoOutlined } from '@ant-design/icons';
import TopNotification from './TopNotification';




const { Header } = Layout;
interface Props { setCollapsed: (collapsed: boolean) => void, collapsed: boolean, sidebarConfig: SidebarConfig }
export default function Topbar({ setCollapsed, collapsed, sidebarConfig }: Props) {
    const router = useRouter();
    const { t } = useTranslation('common');
    const { setLang, lang, messages, setMessages, setCurrentMessage, setPrice, refreshContent } = useSettingStore();
    const { logout, user, getUserInfo } = useAuthStore();

    useMount(() => {
        getUserInfo();
    });

    const breadItems = useMemo(() => {
        const item = sidebarConfig.items.find(item => item.key === router.pathname)
        const items: { href?: string, title: string }[] = [{ /*href: item.key,*/ title: item?.label! }]
        if (sidebarConfig.title) {
            items.unshift({ href: sidebarConfig.href, title: sidebarConfig.title })
        }
        return items;
    }, [router.pathname, sidebarConfig]);

    const { data, loading } = useRequest<ApiResponse, any[]>(getMessageList);

    useRequest<ApiResponse<IPrice>, any>(getChangePrice, {
        pollingInterval: 10000,
        onSuccess(res) {
            setPrice(res.data.price)
        }
    })


    const items = [
        {
            label: t('layout.nav.userInfo'),
            key: '0',
            onClick: () => {
                router.push('/user-info');
            }
        },
        {
            label: "登录教育系统",
            key: '1',
            onClick: () => {
                window.location.href = 'https://greycordcent.mylearndash.com/wp-login.php?loggedout=true'
            }
        },
        {
            label: t('layout.nav.logout'),
            key: '3',
            onClick: () => {
                logout();
                router.replace('/login');
            }
        },
    ];

    const isShowBack = useMemo(() => {
        return sidebarConfig.items.findIndex(item => item.key === router.pathname) < 0;
    }, [router.pathname]);

    const { pathname, asPath, query, locale } = router;
    const menuClick = (key: string) => {
        setLang(key);
        document.cookie = `NEXT_LOCALE=${key}; max-age=31536000; path=/`;
        router.push({ pathname, query }, asPath, { locale: key });
    };

    return (
        <>
            <div className='flex items-center justify-between gap-2 bg-white dark:bg-Constant-G100 dark:text-white pt-8 w-full max-w-screen-lg m-auto text-center'>
                {isShowBack ? <Back /> : <div></div>}

                <Flex gap="middle" wrap align="center" className='text-center'>
                    <div onClick={refreshContent} className="size-10 rounded-full border border-Line-2 leading-[38px] cursor-pointer hover:bg-Fill-3 dark:hover:bg-dark-Fill-3 dark:border-dark-Line-3 relative">
                        <RedoOutlined />
                    </div>
                    <TopNotification />
                    <Popover title="" className="" overlayClassName="w-[120px] popover-default p-0" placement="bottom" content={
                        <>
                            {items.map((item: any) => <div className='py-2 text-center cursor-pointer text-Text-1 hover:bg-Fill-4 rounded-md dark:text-dark-Text-1 dark:hover:bg-dark-Fill-2' key={item?.key} onClick={item.onClick ?? (() => { })}>{item.label}</div>)}
                        </>
                    }>
                        <div className='h-10 gap-2 rounded-full border border-Line-2 flex items-center justify-center p-2 cursor-pointer max-w-40 hover:bg-Fill-3 dark:hover:bg-dark-Fill-3 dark:border-dark-Line-3'>
                            <Avatar size={24} src={user?.avatar} className='rounded-full overflow-hidden'></Avatar>
                            <EllipsisMiddle suffixCount={4}>
                                {user?.nickname || t('layout.nav.defaultUsername')}
                            </EllipsisMiddle>
                        </div>
                    </Popover>
                    <Popover title="" className="" overlayClassName="w-[300px] h-auto popover-default" placement="bottom" content={
                        <div className='text-Text-1 dark:text-dark-Text-2'>
                            <p className='text-Text-3 text-xs font-medium dark:text-dark-Text-3'>{t('layout.nav.generalSettings')}</p>

                            <div className='mt-4 flex items-center'>
                                <Theme />
                                <span className='flex-1 text-Text-1 mx-2 dark:text-dark-Text-1'>{t('layout.nav.darkMode')}</span>
                                <SwitchTheme />
                            </div>
                            {/* <div className='mt-4 flex items-center'>
                                <Question />
                                <span className='flex-1 text-Text-1 mx-2 dark:text-dark-Text-1'>查看文档</span>
                                <Rightsmall />
                            </div> */}

                            <p className='text-Text-3 text-xs font-medium mt-5 mb-2 dark:text-dark-Text-3'>{t('layout.nav.languangeSetting')}</p>

                            <div className=' grid grid-cols-2 flex-wrap gap-2 dark:text-dark-Text-1 '>
                                {
                                    langItems.map(item => {
                                        return (
                                            <span onClick={() => menuClick(item.key)} key={item.key} className={`h-11 flex-1 bg-Fill-3 px-3 rounded-lg leading-[44px] cursor-pointer dark:bg-dark-Fill-3 
                                            ${lang === item.key && 'flex items-center justify-between bg-State-Link-2 text-Text-Brand dark:bg-dark-State-Link-2 dark:text-dark-State-Link-1'} `}>
                                                {item.label}
                                                {lang === item.key && <Check className="size-5"></Check>}
                                            </span>

                                        )
                                    })
                                }
                            </div>


                            <p className='text-Text-3 mt-6'>v.0.0.4
                                {/* · 服务条款 · 用户协议 */}
                            </p>
                        </div>
                    }>
                        <div className='size-10 rounded-full border border-Line-2 leading-[38px] cursor-pointer hover:bg-Fill-3 dark:hover:bg-dark-Fill-3 dark:border-dark-Line-3'>
                            <More className="inline-block" />
                        </div>
                    </Popover>
                    {/* <LangSelect />
                <Dropdown menu={{ items }} trigger={['click']}>
                    <Avatar size={30} src={user?.avatar}></Avatar>
                </Dropdown>
                <SwitchTheme /> */}
                </Flex>
            </div >
        </>
    );
};
