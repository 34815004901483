import React from 'react';
import { Tooltip, Typography } from 'antd';

const { Text } = Typography;

const EllipsisMiddle: React.FC<{ suffixCount: number; children: string, className?: string }> = ({
    suffixCount,
    children,
    className
}) => {
    const start = children.slice(0, children.length - suffixCount);
    const suffix = children.slice(-suffixCount).trim();
    return (
        <>
            {children.length > suffixCount + 6 ?
                <Tooltip title={children} placement="right" >
                    <Text style={{ maxWidth: '100%' }} className={className} ellipsis={{ suffix }}>
                        {start}
                    </Text>
                </Tooltip> :
                <Text>{children}</Text>}
        </>
    );
};

export default EllipsisMiddle