import { post, get, ApiResponse, del, put } from "@/api/axiosInstance";
import { authParams, User } from "@/store/useAuthStore";
import { IListParams, IlistRes } from "@/helper/const";
import { ILedger } from "@/helper/const/user";
import { IMessage } from "@/store/useSetting";

// @Summary 用户登录
// @Produce  application/json
// @Param data body {username:"string",password:"string"}
// @Router /holder/login [post]
export const login = (data: any) => {
  return post("/api/user/login", data);
};

// @Summary 用户登录
// @Produce  application/json
// @Param data body {username:"string",password:"string"}
// @Router /holder/login [post]
export const traderLogin = (data: any) => {
  return post("/api/user/trader_login", data);
};

// @Summary 用户注册
// @Produce  application/json
// @Param data body {username:"string",email:"string",emailVerifyCode:"string"}
// @Router /holder/register [post]
export const register = (data: any) => {
  return post("/api/user/register", data);
};

// SendMailCaptcha 等待开发的的用户接口
// @Tags HcUser
// @Summary 等待开发的的用户接口
// @accept application/json
// @Produce application/json
// @Param data query request.HcUserSearch true "分页获取用户列表"
// @Success 200 {object} response.Response{data=object,msg=string} "获取成功"
// @Router /hcUser/sendMailCaptcha [POST]
export const sendMailCaptcha = (data: any) => {
  return post("/api/user/send_mail_captcha", data);
};

export const getUserInfo = (): Promise<ApiResponse<User>> => {
  return get("/api/user/get_user_info");
};

export const changeUserInfo = (
  data: Partial<User>
): Promise<ApiResponse<User>> => {
  return post("/api/user/change_user_info", data);
};

// export const addWithdrawalAddress = (data: any) => {
//   return post("/api/user/withdrawal_address", data);
// };

export const getWithdrawalAddressHistory = (params: any) => {
  return get("/api/user/withdrawal_address_history", { params });
};

export const delWithdrawalHistory = (params: any) => {
  return del("/api/user/withdrawal_address", { data: params });
};

export const addWithdrawalAddress = (data: any) => {
  return put("/api/user/withdrawal_address", data);
};
export const getMessageList = (params: any) => {
  return get<Record<"list", IMessage[]>>("/api/user/message/list", { params });
};

export const messgaeSend = (data: any) => {
  return put("/api/user/message/send", data);
};

export const messageRead = (data: number[]) => {
  return post("/api/user/message/read", data);
};

export const oauthUsergrant = (data: { scope?: string }) => {
  return post("/api/oauth/user_grant", data);
};

export const getAuthorize = (params: authParams) => {
  // return get("/oauth/authorize", { params });
  return get("/api/authorize", { params });
};

interface IledgerParam extends IListParams {}
export const getUserLedgerList = (params: IledgerParam) => {
  return get<IlistRes<ILedger>>("/api/user/ledger/list", { params });
};
