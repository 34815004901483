import React, { useMemo, useState } from 'react';
import { Menu, Layout } from 'antd';
import type { MenuProps, MenuTheme } from 'antd';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useSettingStore } from '@/store/useSetting';
const { Sider } = Layout;



export default function Sidebar({ collapsed, routes }: { collapsed: boolean, routes: any[] }) {

    const router = useRouter()
    const setting = useSettingStore()
    const menuClick: MenuProps['onClick'] = (e) => {
        router.push(`${e.key}`)
    }
    const defaultSelectedKeys = useMemo(() => {
        if (router.pathname === '/') {
            return ['/']
        } else {
            const actives = routes.filter(item => item.key !== '/' && router.pathname.includes(item.key))
            return [actives[0]?.key]
        }
    }, [router.pathname, routes])

    const routesList = useMemo(() => {
        let list = routes.filter(item => !item.hidden)
        list = list.map(item => {
            if (item.key === defaultSelectedKeys[0]) {
                return {
                    ...item,
                    icon: item.activeicon
                }
            }
            return item
        })
        return list
        // console.log(routes.filter(item => !item.hidden), 'routes.filter(item => !item.hidden)')
        // return routes.filter(item => !item.hidden)

    }, [defaultSelectedKeys, routes])


    return (
        <Sider width={237} trigger={null} collapsible collapsed={collapsed} theme='light' className='dark:bg-Constant-G100 dark:!text-white pl-5' >
            <div className="flex items-center px-4 mb-12 mt-9">
                <Image src='/images/logo-2.png' alt={'logo'} width={24} height={24}></Image>
                <h1 className={`ml-2 text-xl font-semibold ${collapsed ? 'hidden' : ''}`}>Holder Fund</h1>
            </div>

            <Menu
                theme={setting.theme as MenuTheme}
                id="dash-menu"
                onClick={menuClick}
                mode="inline"
                selectable
                selectedKeys={defaultSelectedKeys}
                defaultSelectedKeys={defaultSelectedKeys}
                items={routesList}
                className='border-none dark:bg-Constant-G100'
            />
        </Sider>
    );
};

