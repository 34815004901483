import zhCN from "antd/locale/zh_CN";
import en_GB from "antd/locale/en_GB";
import zh_HK from "antd/locale/zh_HK";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { Locale } from "antd/es/locale";
export default function useLocal() {
  const langs: Record<string, Locale> = {
    en: en_GB,
    zh: zhCN,
    "zh-Hant": zh_HK,
  };
  const router = useRouter();
  const locale = useMemo(
    () => langs[(router.locale as string) || "zh"],
    [router.locale]
  );
  return { locale };
}
