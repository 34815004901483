import React from 'react'
import { useAuthRedirect } from '../../hooks/useAuthRedirect';
import LangSelect from './LangSelect';
import Image from 'next/image';
import img from '@/public/images/login.png'
import logo from '@/public/images/logo-2.png'
import SwitchTheme from './SwitchTheme';
import { useSettingStore } from '@/store/useSetting';


const AuthLayout = ({ children }: any) => {
    const { isTrader } = useSettingStore()
    useAuthRedirect(isTrader ? "/trader" : '/');

    return (
        <div className="flex min-h-screen relative dark:bg-Constant-G100 dark:text-white">
            <LangSelect className='absolute bottom-[54px] right-[44px]' />

            <Image src={img} className=" top-0 -z-10 left-0 w-screen h-screen  absolute object-cover dark:hidden" objectFit="contain" width="100" height="100" alt='login bg'></Image>
            <div className='w-[440px] h-auto shadow-2xl m-auto bg-white p-8 rounded-xl dark:bg-Constant-G100 dark:border-dark-Line-2 dark:border'>
                <div className='flex items-center justify-center mb-3'><Image src={logo} alt='logo' width={32} height={32}></Image> <h2 className='font-semibold text-[27px] ml-2'>Holder Fund</h2></div>
                {children}
            </div>
        </div>
    )
}

export default AuthLayout