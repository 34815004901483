import { THEME, useSettingStore } from '@/store/useSetting'
import { Switch, theme } from 'antd'
import React, { useMemo } from 'react'

type Props = {}

const SwitchTheme = (props: Props) => {
    const setting = useSettingStore()
    const onChange = (checked: boolean) => {
        console.log(checked, 'checked')
        setting.setTheme(checked ? THEME.dark : THEME.light)
    };
    const checked = useMemo(() => (setting.theme === THEME.dark), [setting])
    return (
        <Switch onChange={onChange} checked={checked} />
    )
}

export default SwitchTheme