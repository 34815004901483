import "@/styles/globals.css";
import "@/styles/colors.css";
import "@/styles/theme.scss";
import type { AppProps } from "next/app";
import { appWithTranslation, useTranslation } from 'next-i18next'
import Layout from '@/components/Layout/Layout';
import { ConfigProvider, message, ThemeConfig } from 'antd';
import { theme } from '@/helper/theme';
import { StyleProvider } from '@ant-design/cssinjs';
import useLocal from '@/hooks/useLocal';
import { useRouter } from 'next/router';
import { AuthWhiteList } from '@/helper/const';
import useSidebarConfig from '../hooks/useSidebarConfig'
import getLocaleProps from "@/helper/utils";
import AuthLayout from "@/components/Layout/AuthLayout";
import { useAuthStore } from "@/store/useAuthStore";
import { useEffect, useState } from "react";
import { useAuthRedirect } from "@/hooks/useAuthRedirect";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { BRAND_COLOR, setThemeInDom, THEME, useSettingStore } from "@/store/useSetting";
import { useMount } from "ahooks";

message.config({
  top: 400,
  maxCount: 3,
});

function App({ Component, pageProps }: AppProps) {
  const { locale } = useLocal()
  const router = useRouter()
  const { sidebarConfig } = useSidebarConfig(router.pathname)
  const { setTheme, theme: colorTheme, setIstrader, isTrader, brandColor } = useSettingStore()
  // 创建自定义的加载图标
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  // 设置全局默认
  Spin.setDefaultIndicator(antIcon);

  // useAuthRedirect();
  useEffect(() => {
    const newTheme = setThemeInDom(colorTheme)
    setTheme(newTheme)

    console.log(isTrader, 'isTraderisTraderisTrader')

  }, [])


  const [antTheme, setAntTheme] = useState<ThemeConfig>()

  useEffect(() => {
    console.log(colorTheme === THEME.dark, 'brandColorbrandColor')

    setAntTheme(theme(brandColor, colorTheme === THEME.dark))
    document.documentElement.style.setProperty(
      "--primary-color",
      brandColor
    );

  }, [brandColor, colorTheme])

  return <>
    <ConfigProvider
      locale={locale}
      theme={antTheme}
    >
      <StyleProvider layer>
        {
          router.pathname === "/oauth/authorize" ?
            <Component {...pageProps} /> :
            AuthWhiteList.includes(router.pathname) ?
              <AuthLayout>
                <Component {...pageProps} />
              </AuthLayout> :
              <Layout sidebarConfig={sidebarConfig}>
                <Component {...pageProps} />
              </Layout>
        }

      </StyleProvider>
    </ConfigProvider>
  </>
}

export default appWithTranslation(App)

