import Switch from "./switch.svg";
import Del from "./del.svg";
import Plus from "./plus.svg";
import Error from "./error.svg";
import Pencli from "./pencli.svg";

import AccountDefault from "./account-default.svg";
import AccountExprience from "./account-exprience.svg";
import AccountLefttime from "./account-lefttime.svg";
import AccountYear from "./account-year.svg";
import Copy from "./copy.svg";

import InfoG from "./info-g.svg";
import PeopleAdd from "./people-add.svg";
import Success from "./success.svg";
import Empty from "./empty.svg";

import Recommomed from "./recommomed.svg";
import Checked from "./checked.svg";
import Up from "./up.svg";

import ErrorRequest from "./error-request.svg";
export {
  Switch,
  Del,
  Plus,
  Error,
  Pencli,
  AccountDefault,
  AccountExprience,
  AccountLefttime,
  AccountYear,
  Copy,
  InfoG,
  PeopleAdd,
  Success,
  Empty,
  Recommomed,
  Checked,
  Up,
  ErrorRequest,
};
