import { MESSAGE_STATUS } from '@/helper/const';
import { IMessage, useSettingStore } from '@/store/useSetting';
import { Popover } from 'antd';
import dayjs from 'dayjs';
import Link from 'next/link';
import React, { useEffect, useMemo } from 'react'
import EmptyCont from '../EmptyCont';
import { Bell } from '@/public/svgs/header';
import { useRequest } from 'ahooks';
import { getMessageList } from '@/api/user';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { WebSocketManager } from '@/helper/WebSocketManager';

type Props = {}

const TopNotification = (props: Props) => {
    const router = useRouter()
    const { t } = useTranslation('common');
    const { setLang, lang, messages, setMessages, setCurrentMessage, setPrice, refreshContent } = useSettingStore();
    useEffect(() => {
        const wsManager = new WebSocketManager<IMessage>(); // 使用默认的 WebSocket URL
        wsManager.connect();

        // 监听消息
        wsManager.on('message', (data) => {
            // console.log('Received someEvent:', data);
            setMessages([...messages, data])
        });

        // 清理函数
        return () => {
            wsManager.close();
        };
    }, []);
    const { data: message, run, error: messageError } = useRequest(getMessageList, {
        onSuccess: (result) => {
            if (result?.data?.list) {
                setMessages(result.data.list);
            }
        },
    });
    // useEffect(() => {
    //     run(); // Initial fetch
    // }, [run]);


    const hasNewMessage = useMemo(() => {
        return (messages.findIndex((item) => item.status === MESSAGE_STATUS.new) >= 0);
    }, [messages]);

    return (
        <Popover title="" className="" overlayClassName="w-[400px] popover-default cursor-pointer" placement="bottom" content={
            <>
                {messages.length ? (
                    <>
                        <div className='max-h-[150px] overflow-y-auto'>
                            {messages?.map((item: IMessage) => {
                                return (
                                    <div key={item.ID} className='p-2 mb-1 cursor-pointer' onClick={() => { setCurrentMessage(item); router.push('/message/' + item.ID); }}>
                                        <div className='flex-center gap-1 cursor-pointer'>
                                            <p className='text-Text-3'>{dayjs(item.sendTime).format('YYYY-MM-DD hh:mm:ss')}</p>
                                            {item.status === MESSAGE_STATUS.new && <p className='bg-State-Danger-1 dark:bg-dark-State-Danger-1 w-[6px] h-[6px] rounded-full'></p>}
                                        </div>
                                        <p className='text-Text-2 dark:text-dark-Text-2 cursor-pointer'>{item.title}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <Link href='/message' className='block py-2 cursor-pointer text-Text-Brand text-xs text-center font-medium'>{t('layout.nav.viewMore')}</Link>
                    </>
                ) : <EmptyCont className='min-h-[200px]' error={messageError} />}
            </>
        } trigger="hover" arrow={false}>
            <div onClick={() => router.push('/message')} className="size-10 rounded-full border border-Line-2 leading-[38px] cursor-pointer hover:bg-Fill-3 dark:hover:bg-dark-Fill-3 dark:border-dark-Line-3 relative">
                {hasNewMessage ? <span className='border-2 border-white rounded-full size-[10px] bg-State-Danger-1 absolute right-2 top-2'></span> : ""}
                <Bell className="inline-block" />
            </div>
        </Popover>
    )
}

export default TopNotification