
import React, { useEffect, useState } from 'react';
import Topbar from './Topbar';
import { Button, Layout } from 'antd';
import Sidebar from './Sidebar';

const { Content } = Layout;
import { useTranslation } from 'next-i18next';

import { SidebarConfig } from '@/types/sidebar'
import { useAuthRedirect } from '@/hooks/useAuthRedirect';
import { useResponsive } from 'ahooks';
import { useSettingStore } from '@/store/useSetting';

type Props = {
    children: React.ReactNode
    sidebarConfig?: SidebarConfig
}

const AppLayout = ({ children, sidebarConfig }: Props) => {
    const { t } = useTranslation('common');
    const [collapsed, setCollapsed] = useState(false);
    const { key } = useSettingStore();
    useAuthRedirect('/login', true);
    const responsive = useResponsive();

    useEffect(() => {
        if (!responsive.lg) {
            // setCollapsed(true)
        }
    }, [responsive]);


    return (
        <Layout className='min-h-screen max-h-screen overflow-hidden flex bg-white dark:bg-Constant-G100 dark:text-white' hasSider={true}>
            <Sidebar collapsed={collapsed} routes={sidebarConfig?.items || []} />

            <Layout className='bg-white dark:bg-Constant-G100'>
                {sidebarConfig && <Topbar collapsed={collapsed} setCollapsed={setCollapsed} sidebarConfig={sidebarConfig} />}


                <Content
                    key={key} // Use key to force re-render
                    className="min-h-72 overflow-scroll bg-white dark:bg-Constant-G100 pt-7"
                >
                    <div className='w-full max-w-screen-lg m-auto'>
                        {children}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default AppLayout;
