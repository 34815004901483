import { HOARD_TYPE, MESSAGE_STATUS, SettingStorageKey } from "@/helper/const";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export const setThemeInDom = (
  theme?: THEME,
  old?: THEME
): THEME | undefined => {
  let newtheme = theme;
  if (window.matchMedia("(prefers-color-scheme: dark)").matches && !old) {
    newtheme = THEME.dark;
  }

  if (newtheme === THEME.dark) {
    document.documentElement.classList.add("dark");
    // document.documentElement.setAttribute("data-theme", "dark");
  } else {
    document.documentElement.classList.remove("dark");
    // document.documentElement.setAttribute("data-theme", "light");
  }
  return newtheme;
};
export enum THEME {
  dark = "dark",
  light = "light",
  auto = "auto",
}

export enum BRAND_COLOR {
  USER = "#3f66ff",
  TRADER = "#FE9C28",
}
export interface IMessage {
  ID: string;
  title: string;
  content: string;
  sendTime: string;
  status: MESSAGE_STATUS;
}
interface SettingState {
  theme?: THEME;
  setTheme: (theme: THEME | undefined) => void;
  lang: string;
  setLang: (lang: string) => void;
  exchangeType: HOARD_TYPE;
  setExchangeType: (type: HOARD_TYPE) => void;
  messages: IMessage[];
  setMessages: (messages: IMessage[]) => void;
  currentMessage: IMessage | null;
  setCurrentMessage: (message: IMessage) => void;
  price: number;
  setPrice: (price: number) => void;
  key: number;
  refreshContent: () => void;
  isTrader: boolean;
  setIstrader: (isTrader: boolean) => void;
  brandColor: BRAND_COLOR;
}
export const useSettingStore = create<SettingState>()(
  persist(
    (set, get) => ({
      theme: THEME.light, // Default to light theme
      setTheme(theme: THEME | undefined) {
        const newtheme = setThemeInDom(theme, get().theme);
        console.log(get().theme, "get().theme");
        set(() => ({ theme: newtheme }));
      },
      lang: "zh",
      setLang(lang: string) {
        set(() => ({ lang }));
      },
      exchangeType: HOARD_TYPE.hold,
      setExchangeType(type: HOARD_TYPE) {
        set(() => ({ exchangeType: type }));
      },
      messages: [],
      setMessages: (messages) => set({ messages }),
      currentMessage: null,
      setCurrentMessage: (currentMessage) => set({ currentMessage }),
      price: 0,
      setPrice: (price) => set({ price }),
      key: 0,
      refreshContent: () => set({ key: new Date().getTime() }),
      isTrader: false,
      brandColor: BRAND_COLOR.USER,
      setIstrader: (isTrader) => {
        set({ isTrader });
        if (isTrader) {
          set({ brandColor: BRAND_COLOR.TRADER });
          // document.documentElement.style.setProperty(
          //   "--primary-color",
          //   BRAND_COLOR.TRADER
          // );
        } else {
          set({ brandColor: BRAND_COLOR.USER });
          // document.documentElement.style.setProperty(
          //   "--primary-color",
          //   BRAND_COLOR.USER
          // );
        }
      },
    }),
    {
      name: SettingStorageKey, // localStorage key
      storage: createJSONStorage(() => localStorage), // Use localStorage to persist data
    }
  )
);
