import React from 'react';
import { Empty as EmptyIcon, ErrorRequest } from '~/svgs';
import { useTranslation } from 'next-i18next';

type Props = {
    message?: string,
    className?: string,
    error?: Error
}

const EmptyCont = ({ message, className, error }: Props) => {
    const { t } = useTranslation(['common']);

    return (
        <div className={`h-full flex-center flex-col justify-center ${className}`}>
            {error?.message ? <ErrorRequest /> :
                < EmptyIcon />}
            <p className='text-Constant-G30 text-xs mt-3'>{error?.message || message || t('common:empty.noData')}</p>
        </div>
    )
}

export default EmptyCont;