import { useAuthStore } from "@/store/useAuthStore";
import { ignore } from "antd/es/theme/useToken";

// lib/websocketManager.ts
type MessageType = string; // 定义消息类型
type MessageData = any; // 定义消息数据类型

interface Message {
  type: MessageType;
  data: MessageData;
}

export class WebSocketManager<T = MessageData> {
  private url: string;
  private socket: WebSocket | null = null;
  private listeners: { [key: string]: Array<(data: T) => void> } = {};
  private heartbeatInterval: NodeJS.Timeout | null = null; // 心跳定时器

  constructor(url?: string) {
    const wsUrl = `${process.env.NEXT_PUBLIC_WS_URL}?x-token=${
      useAuthStore.getState().token
    }`; // 从环境变量中获取 WebSocket URL
    this.url = url || wsUrl;
  }

  connect(): void {
    this.socket = new WebSocket(this.url);
    console.log(this.socket, "socket");
    this.socket.addEventListener("open", () => {
      console.log("Connected to the server");
      this.startHeartbeat(); // 开始心跳
    });

    this.socket.addEventListener("message", (event) => {
      this.handleMessage(event.data);
    });

    this.socket.addEventListener("close", () => {
      console.log("Disconnected from the server");
      this.stopHeartbeat(); // 停止心跳
    });
  }

  private handleMessage(message: string): void {
    try {
      // console.log(JSON.parse(message), "messagemessage");
      const parsedMessage: Message = JSON.parse(message);

      // console.log(parsedMessage, "parsedMessageparsedMessage");
      const { type, data } = parsedMessage;

      if (this.listeners[type]) {
        this.listeners[type].forEach((callback) => callback(data));
      }
    } catch (error) {
      console.log(error);
    }
  }

  send(type: MessageType, data: T): void {
    const message: Message = { type, data };
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.error(
        "WebSocket is not open. Current state:",
        this.socket?.readyState
      );
    }
  }

  on(type: MessageType, callback: (data: T) => void): void {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  }

  close(): void {
    this.socket?.close();
    this.stopHeartbeat(); // 停止心跳
  }

  private startHeartbeat(): void {
    this.heartbeatInterval = setInterval(() => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        // @ts-ignore
        this.send("heartbeat", { timestamp: Date.now() }); // 发送心跳消息
      }
    }, 30000); // 每 30 秒发送一次心跳
  }

  private stopHeartbeat(): void {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }
}
