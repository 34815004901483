// hooks/useAuthRedirect.ts
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAuthStore } from '@/store/useAuthStore';
import { useLocalStorageState } from 'ahooks';
import { AuthStorageKey } from '@/helper/const';

export function useAuthRedirect(redirectPath: string, checkForAuth?: boolean) {
    const router = useRouter();
    const { token, oAuthParams } = useAuthStore();

    const [redirected, setRedirected] = useState(false);
    const [auth] = useLocalStorageState<Record<'state', any>>(AuthStorageKey)


    useEffect(() => {
        const renToken = token || auth?.state?.token
        const redirect = router.query?.callback as string || redirectPath
        if (!redirected) {
            if (checkForAuth) {
                if (!renToken) {
                    // if (oAuthParams) {
                    //     router.replace('/oauth/authorize')
                    // } else {
                    router.replace(redirect);
                    // }
                    // 未登录时重定向
                    setRedirected(true);

                }
            } else {
                if (renToken) {
                    if (oAuthParams?.redirect_url) {
                        router.replace('/oauth/authorize')
                    } else {
                        router.replace(redirect);
                    } // 已登录时重定向
                    setRedirected(true);

                }
            }
        }
    }, [redirectPath, checkForAuth, router, token, redirected, auth?.state?.token]);
}
