import { ThemeConfig } from "antd";
import { colors } from "./colors";

export const theme = (primary: string, isdarkmode: boolean): ThemeConfig => ({
  // cssVar: true,
  // hashed: false,
  // cssVar: true,
  components: {
    Form: {
      labelFontSize: 14,
      labelColor: colors["Text-2"],
      // errorColor: colors.default["--State-Danger-1"], // 修改form表单验证失败提示文案颜色
    },
    Button: {
      colorPrimary: primary,
      algorithm: true, // 启用算法
      borderRadius: 99,
      controlHeight: 44,
      lineHeight: 44,
      fontSize: 14,
      controlItemBgActiveDisabled: "rgba(63, 102, 255, 0.4)",
      colorBgContainerDisabled: "rgba(63, 102, 255, 0.4)",
      colorTextDisabled: "rgba(255, 255, 255, 0.4)",
      colorBorder: "none",
      dangerColor: colors["State-Danger-1"],
      defaultColor: colors["Text-1"],
      colorInfoBorder: colors["Fill-3"],
      colorInfoBg: colors["Fill-3"],
      defaultBorderColor: colors["Constant-G100"],
      linkHoverBg: "transparent",
    },
    Input: {
      colorPrimary: colors["Constant-G100"],
      errorActiveShadow: "none",
      colorErrorBorder: colors["Fill-4"],
      algorithm: true, // 启用算法
      borderRadius: 8,
      boxShadow: "none",
      activeShadow: "none",
      activeBorderColor: colors["Constant-G100"],
      activeBg: colors["white"],
      colorBorder: colors["Line-3"],
      colorBgBase: colors["Fill-4"],
      colorBgBlur: colors["white"],
    },
    Checkbox: {
      size: 12,
    },
    Select: {
      colorPrimary: colors["Constant-G100"],
      algorithm: true, // 启用算法
      borderRadius: 8,
      boxShadow: "none",
      boxShadowTertiary: "none",
      boxShadowSecondary: "none",
      colorBorder: colors["Line-3"],
      colorBgBase: colors["Line-3"],
      colorBgBlur: "#fff",
      lineWidthBold: 1,
      optionSelectedBg: "#fff",
      optionActiveBg: "#fff",
    },
    Menu: {
      // darkDangerItemActiveBg: ,
      darkGroupTitleColor: "#fff",

      itemHeight: 44,
      itemActiveBg: colors["Fill-4"],
      itemBorderRadius: 99,
      itemColor: colors["Constant-G80"],
      itemHoverColor: colors["Constant-G100"],
      colorPrimary: colors["Constant-G100"],
      itemSelectedColor: colors["Constant-G100"],
      itemHoverBg: colors["Fill-4"],
      itemSelectedBg: colors["Fill-4"],
      darkItemSelectedBg: colors["dark-Fill-4"],
      darkItemSelectedColor: "#fff",
      darkItemColor: "#D6D4db",
      darkItemBg: "transparent",
      itemPaddingInline: 20,
      controlPaddingHorizontal: 20,
    },
    Layout: {
      bodyBg: colors.white,
      footerBg: colors.white,
      // colorText: colors["Text-1"],
    },
    Popover: {
      // boxShadow: "0px 16px 32px -12px #0E121B1A",
      padding: 0,
    },
    Table: {
      borderColor: colors["Fill-4"],
      cellFontSize: 12,
      headerBg: colors["Fill-4"],
      headerColor: colors["Text-3"],
      headerBorderRadius: 0,
      headerSplitColor: "transparent",
      cellPaddingBlock: 15,
      cellPaddingInline: 12,
    },

    Pagination: {
      itemActiveBg: colors["Constant-G100"],
      itemBg: colors.white,
      itemInputBg: "transparent",
      itemSize: 32,
      borderRadius: 100,
      colorBorder: "transparent",
      itemActiveColorDisabled: "inherit",
    },
    Steps: {
      iconSize: 24,
      colorPrimary: colors["Constant-G100"],
    },
    Modal: {
      colorBgMask: "rgba(17, 18, 18, 0.8)",
      colorIcon: colors["Text-3"],
      colorIconHover: colors["Constant-G100"],
      colorSplit: colors["Line-2"],
      titleColor: colors["Constant-G100"],
      fontWeightStrong: 500,
      borderRadiusLG: 12,
      colorBorder: colors["Line-2"],
      boxShadow: "0px 16px 32px -12px #0E121B1A",
      titleFontSize: 16,
      titleLineHeight: 1.2,
      zIndexBase: 10,
      contentBg: "#fff",
    },
    Slider: {
      colorPrimary: colors["Constant-G100"],
      controlSize: 14,
      dotActiveBorderColor: colors["Constant-G100"],
      dotBorderColor: colors["Constant-G30"],
      dotSize: 12,
      handleActiveColor: colors["Fill-1"],
      // handleColor: colors["Fill-1"],
      handleColorDisabled: colors["Constant-G100"],
      handleLineWidth: 3,
      handleSize: 6,
      handleSizeHover: 6,
      handleLineWidthHover: 3,
      handleColor: colors["Fill-1"],
      railBg: colors["Fill-4"],
      railHoverBg: colors["Fill-4"],
      trackBg: colors["Constant-G100"],
      trackHoverBg: colors["Constant-G100"],

      // handleActiveOutlineColor: colors["Constant-G100"],
      // handleLineWidth: 1,
    },
    Collapse: {
      headerPadding: "10px 0 4px",
      contentPadding: "",
    },
  },
  token: {
    // Seed Token，影响范围大
    // colorPrimary: "#3182CE",
    colorPrimary: primary,
    colorText: "inherit",
    fontSize: 14,
    controlHeight: 40,
    colorError: colors["State-Danger-1"],
    colorErrorBg: colors["State-Danger-2"],
    colorErrorText: colors["State-Danger-1"],
    colorErrorBorder: colors["State-Danger-1"],
    colorErrorActive: colors["State-Danger-1"],
    colorErrorTextActive: colors["State-Danger-1"],

    colorSuccessText: colors["State-Success-1"],
    colorSuccessBg: colors["State-Success-2"],

    colorWarning: colors["State-Warning-1"],
    colorWarningBg: colors["State-Warning-2"],

    colorBgElevated: "transparent",

    // 派生变量，影响范围小
    colorBgContainer: isdarkmode ? "#141414" : "#fff",
    borderRadius: 12,
    boxShadowSecondary: "none",
    colorBorder: isdarkmode ? "#444444" : "#b9b7b7",
  },
});
