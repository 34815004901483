import React from 'react'
import Arrowleft from '~/svgs/arrowleft.svg';
import { useRouter } from 'next/router';

type Props = {}

const Back = (props: Props) => {
    const router = useRouter()
    return (
        <div onClick={() => router.back()} className='size-10 flex-center justify-center bg-Fill-3 rounded-full cursor-pointer dark:bg-dark-Fill-3'>
            <Arrowleft className="inline-block" />
        </div>
    )
}

export default Back