import { post, get, ApiResponse, put } from "@/api/axiosInstance";
import { buildQueryString } from "@/helper/libs";

export const showBnDepositAddress = (params: {
  Coin: string;
  Network: string;
}) => {
  return get(`/api/account/show_deposit_address`, { params });
};
export const getOriginalAddresses = () => {
  return get(`/hcOriginalAddress/getOriginalAddresses/`);
};

export const createOriginalAddress = (address: string) => {
  return post(`/hcOriginalAddress/createOriginalAddress/?address=${address}`);
};

// 同步充值数据
export const accept = (TxID: string) => {
  return post(`/api/deposit/accept/?TxID=${TxID}`);
};

interface getDepositsParams {
  keyword?: string;
  page?: number;
  pageSize?: number;
}
// 获取充值列表
export const getDeposits = (params: getDepositsParams) => {
  return get(`/api/deposit/get_deposits`, { params });
};
export interface changeParams {
  quantity: string | number;
  side: "SELL" | "BUY";
  symbol: "BTCUSDT" | "USDTBTC";
  type: "LIMIT" | "MARKET";
}

export const change = (data: changeParams) => {
  // const queryString = buildQueryString(params);
  return post(`/api/asset/trade`, data);
};

export interface IPrice {
  symbol: string;
  price: number;
}
export const getChangePrice = (
  params: { symbol: string } = { symbol: "BTCUSDT" }
): Promise<ApiResponse<IPrice>> => {
  return get("/api/asset/price", { params });
};

export interface IWithdrawal {
  coin: string;
  amount: number;
  network: string;
  address: string;
}
export const withdrawal = (data: IWithdrawal) => {
  return post("/api/user/withdrawal", data);
};

export const withdrawalHistory = (params: getDepositsParams) => {
  return get("/api/user/withdrawal_history", { params });
};

export const getEvangelList = (params: getDepositsParams) => {
  return get("/api/Evangel/list", { params });
};

export const evangelApply = () => {
  return put("/api/evangel/apply");
};

export const getMemberList = (params: getDepositsParams) => {
  return get("/api/member/list", { params });
};

export const memberBuy = (data: {
  captchaId?: string;
  memberID: number;
  verifyCode?: string;
}) => {
  return put("/api/member/buy", data);
};

export const getIsApplying = () => {
  return get("/api/evangel/isApplying");
};
