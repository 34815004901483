import Bitcoin from "./bitcoin.svg";
import BitcoinActive from "./bitcoinActive.svg";
import Exchange from "./exchange.svg";
import ExchangeActive from "./exchangeActive.svg";
import Home from "./home.svg";
import HomeActive from "./homeActive.svg";
import PieChart from "./pieChart.svg";
import PieChartActive from "./pieChartActive.svg";
import Preach from "./preach.svg";
import PreachActive from "./preachActive.svg";
import Member from "./member.svg";
import MemberActive from "./memberActive.svg";

export {
  Bitcoin,
  BitcoinActive,
  Exchange,
  ExchangeActive,
  Home,
  HomeActive,
  PieChart,
  PieChartActive,
  Preach,
  PreachActive,
  Member,
  MemberActive,
};
