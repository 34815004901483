// api/axiosInstance.ts
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useAuthStore } from "@/store/useAuthStore";
import { message } from "antd";
import { RES_CODE } from "@/helper/const";

// 定义通用的响应接口
export interface ApiResponse<T = any> {
  data: T;
  code: number;
  msg: string;
}

// 创建 axios 实例
const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL, // 配置 API 基础路径
  timeout: 20000, // 设置超时时间 (ms)
  headers: {
    "Content-Type": "application/json",
  },
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    const { token, user } = useAuthStore.getState(); // 通过 getState() 获取 token

    if (token) {
      config.headers = new axios.AxiosHeaders(config.headers);
      config.headers.set("x-token", token);
      config.headers.set("x-user-id", user?.ID);
    }

    // 如果请求的 URL 是 /api/authorize，使用本地服务路径
    if (config.url?.startsWith("/api/authorize")) {
      config.baseURL = "";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error); // 处理请求错误
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  (response: AxiosResponse<ApiResponse>) => {
    if (response.data.code && response.data.code !== RES_CODE.success) {
      message.error(response.data.msg);
    }
    // 处理成功响应
    return response;
  },
  (error: AxiosError) => {
    message.error(error.message);
    // 全局处理错误响应，例如 token 过期，提示错误信息等
    if (error.response?.status === RES_CODE.no_auth) {
      // 清除状态并退出登录
      useAuthStore.getState().logout();
      // 可以跳转到登录页面
      window.location.href = "/login";
      // window.history.pushState({}, "", "/login");
    }

    return Promise.reject(error);
  }
);

// 修改封装的请求方法
export const get = async <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
  const response = await axiosInstance.get<ApiResponse<T>>(url, config);
  // console.log(response, "responseresponse");
  return response.data;
};

export const post = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
  const response = await axiosInstance.post<ApiResponse<T>>(url, data, config);
  return response.data;
};

export const put = async <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
  const response = await axiosInstance.put<ApiResponse<T>>(url, data, config);
  return response.data;
};

export const del = async <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
  const response = await axiosInstance.delete<ApiResponse<T>>(url, config);
  return response.data;
};

export default axiosInstance;
