import { SidebarConfig } from "@/types/sidebar";
import { Bitcoin, BitcoinActive, Home, HomeActive, Exchange, ExchangeActive, PieChart, PieChartActive, Preach, PreachActive, Member, MemberActive } from '~/svgs/menu';

import { FaHandHoldingUsd, FaBitcoin } from "react-icons/fa";
import { RiExchangeDollarLine, RiLayoutGrid2Line } from "react-icons/ri";
import { TbRecharging } from "react-icons/tb";
import { GiSellCard } from "react-icons/gi";

import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useSettingStore } from "@/store/useSetting";
export default function useSidebarConfig(path: string): Record<string, SidebarConfig> {
  const { t } = useTranslation("common");
  const { isTrader } = useSettingStore();
  const router = useRouter();
  const { strategy_id } = router.query;
  const sidebarConfigs: Record<string, SidebarConfig> = {

    asset: {
      href: '/',
      title: t('layout.nav.asset'),
      items: [
        // {
        //   key: "/assets",
        //   label: t("layout.nav.overview"),
        //   icon: <DashboardOutlined />,
        // },
        {
          key: "/asset/rechange",
          label: t("layout.nav.deposit"),
          icon: <TbRecharging />,
        },
        {
          key: "/asset/withdraw",
          label: t("layout.nav.withdraw"),
          icon: <FaHandHoldingUsd />,
        },
        {
          key: "/asset/exchange-usdt",
          label: t("layout.nav.exchange-usdt"),
          icon: <RiExchangeDollarLine />,
        },
        // {
        //   key: "/asset/address",
        //   label: t("layout.nav.address"),
        //   icon: <RiExchangeDollarLine />,
        // },
      ],
    },
    "earn-coin": {
      href: "/earn-coin",
      title: t('layout.nav.earncoin'),
      items: [
        {
          key: `/earn-coin/[strategy_id]`,
          // label: `strategy info[${strategy_id}]`,
          label: `${t("layout.nav.strategyinfo")}[${strategy_id}]`,
          hidden: true
        },
        {
          key: "/earn-coin/CDTD",
          label: t("layout.nav.CDTD"),
          icon: <GiSellCard />,
        },
        {
          key: "/earn-coin/dctd-buy",
          label: "抄顶逃底申购页",
          hidden: true
        },
        {
          key: "/earn-coin/grid",
          label: t("index.grid"),
          icon: <RiLayoutGrid2Line />,
        },
        {
          key: "/earn-coin/orders",
          label: t('layout.nav.orderLists'),
          // icon: <RiLayoutGrid2Line />,
          hidden: true
        }
      ]
    },
    default: {
      href: '/',
      items: [
        ...(isTrader ? [{
          key: "/trader",
          label: "交易",
          icon: <Exchange />,
          activeicon: <ExchangeActive />
        }, {
          key: "/chart",
          label: t("layout.nav.chart"),
          icon: <PieChart />,
          activeicon: <PieChartActive />
        },] : [{
          key: "/",
          label: t("layout.nav.overview"),
          icon: <Home />,
          activeicon: <HomeActive />
        },
        {
          key: "/hoarding",
          label: t("layout.nav.hoarding"),
          icon: <Bitcoin />,
          activeicon: <BitcoinActive />
        },

        {
          key: "/earn-coin",
          label: t("layout.nav.earncoin"),
          icon: <Exchange />,
          activeicon: <ExchangeActive />
        }, {
          key: "/chart",
          label: t("layout.nav.chart"),
          icon: <PieChart />,
          activeicon: <PieChartActive />
        },
        {
          key: "/account-plan",
          label: t("layout.nav.accountplan"),
          icon: <Member />,
          activeicon: <MemberActive />
        },
        {
          key: "/preach",
          label: t("layout.nav.preachPlan"),
          icon: <Preach />,
          activeicon: <PreachActive />
        }]),

      ],
    },
  };

  const category = path.split("/")[1];
  const self = path.split("/")[2];
  return {
    sidebarConfig: sidebarConfigs['default']
    //  已废弃逻辑
    // sidebarConfig: self ? sidebarConfigs[category] || sidebarConfigs['default'] : sidebarConfigs['default']
  }
}
