import { DownOutlined, GlobalOutlined } from '@ant-design/icons';
// import { Button, Dialog, DropdownMenu, Flex, Text, TextField } from '@radix-ui/themes';
import { Dropdown, Popover, Space, Typography } from 'antd';
import type { MenuProps } from 'antd';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react'
import LangIcon from '~/svgs/lang.svg';
import Rightsmall from '~/svgs/header/rightsmall.svg';
import { useSettingStore } from '@/store/useSetting';
import { langItems } from '@/helper/const';



type Props = {
    className?: string
}

export default function LangSelect({ className }: Props) {
    const { setLang, lang } = useSettingStore()
    const router = useRouter();
    const { pathname, asPath, query, locale } = router;
    const menuClick = (key: string) => {
        setLang(key)
        document.cookie = `NEXT_LOCALE=${key}; max-age=31536000; path=/`;
        router.push({ pathname, query }, asPath, { locale: key });
    }

    const defaultSelectedKeys = useMemo(() => {
        return locale ?? 'en'
    }, [locale])
    return (

        <Popover title="" className="" overlayClassName="w-[300px] h-[130px] popover-default p-0" placement="top" content={
            <>
                {langItems?.map(item => <div className='p-2 mb-1 cursor-pointer flex-center justify-between dark:text-white' key={item?.key} onClick={() => menuClick(item.key)}>
                    {item.label}
                    {defaultSelectedKeys === item.key && <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.83594 10.8337L8.33594 13.3337L14.1693 6.66699" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>}

                </div>)}</>
        }>
            <div className={`flex-center cursor-pointer  h-[20px]  ${className}`}>
                <LangIcon className="text-xl text-black flex-shrink-0" />
                <span className='ml-1 mr-[2px] flex-shrink-0'><span>{langItems.find(item => item.key === lang)?.title}</span></span>
                <Rightsmall className="flex-shrink-0 -rotate-90" />
            </div>
        </Popover>


    )
}

