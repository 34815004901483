import {
  AccountGrade,
  ASSET_TYPE,
  AuthStorageKey,
  COIN_TYPE,
  IWhitelistItem,
} from "@/helper/const";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { setCookie } from "nookies";
import { add } from "mathjs";
import { getUserInfo } from "@/api/user";
import { ApiResponse } from "@/api/axiosInstance";
export interface User {
  ID: number;
  nickname: string;
  gender: string;
  email: string;
  avatar: string;
  phone: string;
  desc: string;
  created_at?: string;
  UpdatedAt?: string;
  authority_id?: string;
  uuid?: string;
  referral_code?: string;
  referral_id?: string;
  ref_count: number;
  evangel: IEvangel;
  evangelID: string;
  evangelRefCode: string;
  evangelRefLink: string;
  member: IMember;
  memberExpireAt: string;
  memberId: string;
  assets: Asset[];
  asset?: NumberType;
  totalAssets?: Record<COIN_TYPE, number>;
}
interface IEvangel {
  ID: number;
  memberBonus: number;
  profitBonus: number;
  levelName: string;
  referNum: number;
  created_at: string;
}
interface IMember {
  ID: AccountGrade;
  price: number;
  updated_at: string;
}

export interface Asset {
  coin: COIN_TYPE;
  amount: number;
  assetType: ASSET_TYPE;
}

type NumberType = Record<ASSET_TYPE, Record<COIN_TYPE, number>>;
export type authParams = {
  client_id: string;
  redirect_url: string;
  response_type: string;
  scope: string;
  state: string;
} | null;
interface AuthState {
  showBan: boolean;
  user: User | null;
  token: string | null;
  totalAssets: Record<COIN_TYPE, number> | null;
  assets: NumberType | null;
  whitelist: IWhitelistItem[] | [];
  setUser: (user: User) => void;
  setShowBan: (showBan: boolean) => void;
  setTotalAssets: (totalAssets: Record<COIN_TYPE, number>) => void;
  setToken: (token: string) => void;
  logout: () => void;
  getUserInfo: () => Promise<void>;
  setWhitelist: (whiteList: IWhitelistItem[]) => void;
  oAuthParams: authParams;
  setOAuthParams: (oauthParams: authParams) => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      token: null,
      assets: null,
      showBan: false,
      whitelist: [],
      setShowBan: (showBan: boolean) => set(() => ({ showBan })),
      totalAssets: null,
      setTotalAssets: (totalAssets: Record<COIN_TYPE, number>) =>
        set(() => ({ totalAssets })),
      setUser: (user: User) => set(() => ({ user })),
      setWhitelist: (whiteList: IWhitelistItem[]) =>
        set(() => ({ whitelist: whiteList })),
      setToken: (token: string) => {
        set(() => ({ token }));
        token &&
          setCookie(null, "token", token, {
            maxAge: 30 * 24 * 60 * 60, // 30 days
            path: "/",
          });
      },
      logout: () => {
        set(() => ({
          user: null,
          token: null,
        }));
        setCookie(null, "token", "", {
          maxAge: -1, // Expire the cookie
          path: "/",
        });
      },
      getUserInfo: async () => {
        const res: ApiResponse<User> = await getUserInfo();
        const user = res.data;
        let assets: NumberType = {} as NumberType;
        user?.assets?.forEach((item) => {
          if (!assets[item.assetType]) {
            assets[item.assetType] = {} as Record<COIN_TYPE, number>;
          }
          assets[item.assetType][item.coin] = item.amount;
        });
        const totalAssets = {
          [COIN_TYPE.BTC]: add(
            assets?.[ASSET_TYPE.cash]?.[COIN_TYPE.BTC] ?? 0,
            assets?.[ASSET_TYPE.strategy]?.[COIN_TYPE.BTC] ?? 0
          ),
          [COIN_TYPE.USDT]: add(
            assets?.[ASSET_TYPE.cash]?.[COIN_TYPE.USDT] ?? 0,
            assets?.[ASSET_TYPE.strategy]?.[COIN_TYPE.USDT] ?? 0
          ),
        };
        set(() => ({
          user,
          assets,
          totalAssets,
        }));
      },
      oAuthParams: null,
      setOAuthParams: (oAuthParams: authParams) => set(() => ({ oAuthParams })),
    }),
    {
      version: 0.04,
      name: AuthStorageKey, // localStorage key
      storage: createJSONStorage(() => localStorage), // Use localStorage to persist data
    }
  )
);
