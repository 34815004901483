const primaryColor = "var(--primary-color)";

export const colors = {
  "Constant-Black": "#000",
  "Constant-G100": "#111212",
  "Constant-G90": "#2B2C31",
  "Constant-G80": "#3C3E45",
  "Constant-G70": "#474A52",
  "Constant-G60": "#5E626B",
  "Constant-G50": "#81848F",
  "Constant-G40": "#9B9FA8",
  "Constant-G30": "#B8BBC2",
  "Constant-G20": "#D4D6DB",
  "Constant-G15": "#E6E8EB",
  "Constant-G10": "#EDEEF0",
  "Constant-G5": " #F1F2F3",
  white: "#fff",
  "Background-1": "#fff",
  "Background-2": "#F7F8F9",
  "Line-1": "#D8DADE",
  "Line-2": "#E6E8EB",
  "Line-3": "#F5F6F7",
  "Fill-1": "#111212",
  "Fill-2": "#E6E8EB",
  "Fill-3": "#F1F2F3",
  "Fill-4": "#F5F6F7",
  "Fill-5": primaryColor,
  "Text-1": "#111212",
  "Text-2": "#3C3E45",
  "Text-3": "#81848F",
  "Text-Disabled": "#B8BBC2",
  "Text-On-Color": "#fff",
  "Text-Brand": primaryColor,
  "State-Danger-1": "#EB4B6D",
  "State-Danger-2": "#FDEDF0",
  "State-Warning-1": " #FF7E33",
  "State-Warning-2": "#FFF2EB",
  "State-Success-1": "#2EB265",
  "State-Success-2": "#EAF6ED",
  "State-Link-1": primaryColor,
  "State-Link-2": "#E8EEFE",
  "dark-Constant-Black": "#000",
  "dark-Constant-G100": "#111212",
  "dark-Constant-G90": "#2B2C31",
  "dark-Constant-G80": "#3C3E45",
  "dark-Constant-G70": "#474A52",
  "dark-Constant-G60": "#5E626B",
  "dark-Constant-G50": "#81848F",
  "dark-Constant-G40": "#9B9FA8",
  "dark-Constant-G30": "#B8BBC2",
  "dark-Constant-G20": "#D4D6DB",
  "dark-Constant-G15": "#E6E8EB",
  "dark-Constant-G10": "#EDEEF0",
  "dark-Constant-G5": "#F1F2F3",
  "dark-white": "#fff",
  "dark-Background-1": "#111212",
  "dark-Background-2": "#191B1B",
  "dark-Line-1": "#474A52",
  "dark-Line-2": "#2B2C31",
  "dark-Line-3": "#000",
  "dark-Fill-1": "#fff",
  "dark-Fill-2": "#323439",
  "dark-Fill-3": "#26272B",
  "dark-Fill-4": "#1D1E20",
  "dark-Fill-5": primaryColor,
  "dark-Text-1": "white",
  "dark-Text-2": "#D4D6DB",
  "dark-Text-3": "#9B9FA8",
  "dark-Text-Disabled": "#474A52",
  "dark-Text-On-Color": "#111212",
  "dark-Text-Brand": primaryColor,
  "dark-State-Danger-1": "#CA3F64",
  "dark-State-Danger-2": "#291C21",
  "dark-State-Warning-1": "#FF7E33",
  "dark-State-Warning-2": "#2E221D",
  "dark-State-Success-1": "#2CA860",
  "dark-State-Success-2": "#192621",
  "dark-State-Link-1": primaryColor,
  "dark-State-Link-2": "#1C2231",
};
